@charset "UTF-8";
/*====================================================================================
0）リセット
====================================================================================*/
/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

audio:not([controls]) {
  display: none;
}

b,
strong {
  font-weight: bolder;
}

button {
  -webkit-appearance: button;
  overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button,
select {
  text-transform: none;
}

details {
  display: block;
}

hr {
  overflow: visible;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
}

input {
  -webkit-border-radius: 0;
}

input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
}

input[type="number"] {
  width: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

summary {
  display: block;
}

svg:not(:root) {
  overflow: hidden;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

svg {
  fill: currentColor;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat;
}

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 66.6666666667% sans-serif;
}

a {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
}

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

nav ol,
nav ul {
  list-style: none;
}

small {
  font-size: 75%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}
